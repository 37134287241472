import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'asapClient'
})
export class AsapClientPipe implements PipeTransform {

    public static dictionary: any = {
        appName: 'Plataforma ASAP',
        favicon: null,
        clientName: 'ASAP',
        welcome: 'Bem vindo',
        intro: 'Você está na Plataforma ASAP',
        environmentWelcomeParagraph: 'Nesta página você terá acesso aos conteúdos e diretrizes que serão apresentados aos novos colaboradores',
        influencersParagraph: 'Você também pode ser um influenciador',
        loginBG: '',
        logoLight: 'assets/img/logo-light.svg',
        logoDark: 'assets/img/logo.png',
        logoFooter: 'assets/img/logo.png',
        logohelpdesk: 'assets/img/helpdesk.png',
        logoEAD: 'assets/img/logo.png',
        emblem: '',
        bannerHome: '',
        bannerPage: '',
        bannerVoucher: '',
        mainColor: '#000000',
        bgClient: '#ffffff',
        bgSidebar: '#000000',
        linkSidebar: '#ffffff',
        bgNavbar: '#000000',
        linkNavbar: '#ffffff',
        bgFooter: '#000000',
        linkFooter: '#ffffff',
        bgBtn: '#000000',
        textBtn: '#ffffff',
        bgSidebarViewer: '#000000',
        bgBtnViewer: '#000000',
        textBtnViewer: '#ffffff',
        textBtnViewerActive: '#666666',
        primaryColor: '#000000',
        primaryColorHover: '#6666666',
        supplementaryDataRequired: false,
        loginAD: false,
        firebase: {
            apiKey: "AIzaSyCnQ7hg9qn8mrS3zSLX-xeXX3wKbuC2GXA",
            authDomain: "admin-17e4f.firebaseapp.com",
            databaseURL: "https://admin-17e4f.firebaseio.com",
            projectId: "admin-17e4f",
            storageBucket: "admin-17e4f.appspot.com",
            messagingSenderId: "572223664609",
            appId: "1:572223664609:web:13272a61913c23551e62a0"
        },
        documentsLayout: '',
        navbarAppDisplay: true,
        _navbarHelpdeskDisplay: true,
        navbarFAQDisplay: false,
        navbarNotificationDisplay: true,
        registerAccount: false,
        rescueAccount: false,
        loginFacebook: false,
        loginBGLight: false,
        loginUserPass: true,
        loginTermUsername: 'Email',
        loginTermPassword: 'Senha',
        loginUniqueCode: false,
        loginUniqueCodeTerm: 'Código',
        navbarSearchDisplay: false,
        navbarPlataformsStyle: 'dropdown',
        navbarHelpdeskStyle: 'icon',
        navbarNotificationStyle: 'icon',
        navbarPlataformsDisplay: true,
        navbarTransparent: false,
        grid: 'container',
        template: {
            platform_type: 'b2b',
            emailOk: true
        },
        footerFacebook: '',
        footerInstagram: '',
        footerLinkedin: '',
        footerYoutube: '',
        loginVoucher: false,
        navbarPagesPosition: 'left',
        navbarMenuItems: []
    };

    public static setDictionary(dic:any) {
        for (const key in dic) {
            if (dic[key] !== undefined) {
                AsapClientPipe.dictionary[key] = dic[key];
            }
        }
        console.log('this.dictionary', this.dictionary);
    }

    transform(value: any, args?: any): any {
        if (AsapClientPipe.dictionary[value] !== undefined) {
            return AsapClientPipe.dictionary[value];
        }
        return value;
    }

}

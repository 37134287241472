import { Component} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ThemeService } from '../services/theme.service';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css'],
    providers: [ ]
})
export class NotFoundComponent {

    constructor(
        private theme: ThemeService,
        private router: Router
    ) {
        if (!this.theme.clientNotFound) {
            this.router.navigate(['/']);
        }
    }


}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { VoucherStore } from '../store/voucher.store';
import { Observable } from 'rxjs';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {

  constructor(public voucherStore: VoucherStore) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clientID = this.getClientByReqAndVoucher(req.url);
    const params:any = {};

    if (clientID) {
      if (!req.url.includes('client_id')) {
        params.params = req.params.append('client_id', clientID);
      }
    }

    let changeReq = req.clone(params);
    return next.handle(changeReq);
  }

  getClientByReqAndVoucher(url: string): string {
    if (this.isLoginOrRegister(url) && this.voucherStore.state.isValid) {
      return '' + this.voucherStore.state.voucher.client_id;
    }
    return localStorage.getItem('client');
  }

  isLoginOrRegister(url: string): boolean {
    const loginUri = 'oauth/access_token';
    const registerUri = 'v2/client/register';
    const socialUri = 'social_auth/grupo';

    return (url.includes(loginUri) || url.includes(registerUri) || url.includes(socialUri));
  }
}

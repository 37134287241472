import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectComponent } from './auth/redirect/redirect.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AsapClientPipe } from './pipes/asap-client.pipe';

const routes: Routes = [
    {
        path: '',
        component: RedirectComponent
    },
    {
        path: 'active-directory',
        // canLoad: [AuthGuardService],
        loadChildren: () => import('./auth/active-directory/active-directory.module').then(m => m.ActiveDirectoryModule)
    },
    {
        path: 'login',
        // canLoad: [AuthGuardService],
        loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'recovery',
        // canLoad: [AuthGuardService],
        loadChildren: () => import('./auth/recovery/recovery.module').then(m => m.RecoveryModule)
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: '**',
        redirectTo: '/on/**'
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AsapCrudModule, Global } from 'asap-crud';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ThemeService } from './services/theme.service';
import { AuthService } from './services/auth.service';
import { ClienteService } from './services/client.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AsapClientPipe } from './pipes/asap-client.pipe';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { NotFoundComponent } from './not-found/not-found.component';
import { ClientInterceptor } from './@core/interceptors/client-interceptor';
import { TranslateSharedModule } from './translate/translate.module';
import { NgxMaskModule } from 'ngx-mask';

let configLoaded = false;

if (environment.production) {
    window.console.log = function () { };
}

export function asapConfigFactory(http: HttpClient) {
    return () => new Promise<boolean>((resolve) => {
        const wait = () => {
            if (configLoaded) {
                console.log('[asapConfigFactory] - ClientPipe Loaded', new Date());
                setTimeout(() => {
                    resolve(true);
                }, 500);
            } else {
                console.log('[asapConfigFactory] - Aguardando ClientPipe', new Date());
                setTimeout(wait, 500);
            }
        };
        wait();
    });
}

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        Ng2IziToastModule,
        TranslateSharedModule,
        NgxMaskModule.forRoot(),
        AsapCrudModule
    ],
    declarations: [
        AppComponent,
        NotFoundComponent
    ],
    exports: [
        Ng2IziToastModule,
        NgxMaskModule,
        AsapCrudModule
    ],
    providers: [
        ThemeService,
        AuthService,
        ClienteService,
        {
            provide: APP_INITIALIZER,
            useFactory: asapConfigFactory,
            deps: [HttpClient],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ClientInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private theme: ThemeService,
        private auth: AuthService,
        private client: ClienteService,
        private global: Global,
        private title: Title,
        private router: Router
    ) {
        let noRedirect = ['/login','/active-directory','/recovey','/not-found']
        
        if (this.global.loggedUser()) {
            if (noRedirect.includes(window.location.pathname)) {
                console.log("[asap-login] AppModule HREF A");
                window.location.href = "/on"
            }
            else if (!window.location.href.includes('/on')) {
                console.log("[asap-login] AppModule HREF B");
                window.location.href = "/on" + window.location.pathname;
            }
        } 
        else {
            if (!noRedirect.includes(window.location.pathname)) {
                if (window.location.pathname != "" && window.location.pathname != "/") {
                    localStorage.setItem('redirect_url',window.location.pathname);
                }
            }
        }

        localStorage.setItem('apiURL', environment.apiUrl);

        localStorage.setItem('feed_prefix', '');

        console.log('[asap-login] Theme Host:', window.location.hostname);
        console.log('[asap-login] Route', window.location.pathname);

        let host = window.location.hostname;

        if (host === 'localhost') {

            // ================================================================================== //
            // ***** Lista de Clientes: Habilite/Desabilite a linnha com o cliente desejado. ***** //
            // ================================================================================== //

            // ** DIMED ** //

            // host = 'grupodimed.portal.hmgasap.com.br';

            // ** CONTAS ASAP ** //

            // host = 'asap.portal.hmgasap.com.br';
            // host = 'asapeventos.portal.hmgasap.com.br';
            // host = 'tecnologia.portal.hmgasap.com.br';
            // host = 'dev.portal.hmgasap.com.br';
            // host = 'marketingportal.portal.hmgasap.com.br';
            // host = 'asapbot.portal.hmgasap.com.br';
            // host = 'treinamento.portal.hmgasap.com.br'
             //  host = 'genese.portal.hmgasap.com.br';

            // **********************************************

            // ** CONTAS UNIVERSIDADE SMART FIT ** //
          
             host = 'portal.smartfit.com.br';
             //host = 'smartmexico.portal.hmgasap.com.br';
             //host = 'smartcolombia.portal.hmgasap.com.br';
            // host = 'smartfitacademylatam.portal.hmgasap.com.br';
            // host = 'universidad.smartfit.com';
            // host = 'smartargentina.portal.hmgasap.com.br';
            // host = 'smartdemo.portal.hmgasap.com.br';
            // host = 'demo.portal.hmgasap.com.br';

            // **********************************************

            // ** CONTA BEMOL ** //
            // host = 'bemol.portal.hmgasap.com.br';
            // host = 'bemoldemo.portal.hmgasap.com.br';
            // host =  'ctg.edital.hmgasap.com.br';

            // **********************************************

            // ** CONTAS START ** //

            // host = 'start.portal.hmgasap.com.br';
            // host = 'startdemo.portal.hmgasap.com.br/';

            // **********************************************

            // ** CONTA TRÊS CORAÇÕES ** //

            // host = 'tres.portal.hmgasap.com.br';
            // host = 'tresdemo.portal.hmgasap.com.br';

            // **********************************************

            // ** CONTA BIO RITMO ** //

            // host = 'bioritmo.portal.hmgasap.com.br';

            // **********************************************

            // ** CONTA FITNESS LINK ** //

            // host = 'fitnesslink.plataformaasap.com.br';

            // **********************************************

            // ** CONTA QUIXOTE ** //

            // host = 'quixote.consultoriaasap.com.br';

            // **********************************************

            // ** CONTA JA BRASIL ** //

            // host = 'jabrasil.portal.hmgasap.com.br';
            // host = 'live.jarj.org.br'
            // host = 'jademo.portal.hmgasap.com.br';

            // **********************************************

            // ** CONTA ESPAÇO VIDA ** //

            // host = 'espacovida.portal.hmgasap.com.br';

            // **********************************************

            // ** CONTA UPLAB ** //

            // host = 'uplab.poral.hmgasap.com.br';
            // host = 'ccfe.portal.hmgasap.com.br';

            // **********************************************

            // ** CONTA REALIZA ** //

            // host = 'realiza.portal.hmgasap.com.br';

            // **********************************************

            // host = 'eixosp2020.com.br';

            // host = 'ets.portal.hmgasap.com.br';

            // host = 'acadbrasil.portal.hmgasap.com.br';

            // host = 'live.portal.hmgasap.com.br';

            // host = 'evobit.portal.hmgasap.com.br';

            // host = 'hipconecta.b2c.hmgasap.com.br';

            // host = 'grupodimed.portal.hmgasap.com.br';
            // host = 'eixo.portal.hmgasap.com.br';

            // host = 'seminariodeimpacto.portal.hmgasap.com.br';
            // host = 'marketingportal.portal.hmgasap.com.br';
            // host = 'ergos.consultoriaasap.com.br';
            // host = 'sistemabchile.portal.hmgasap.com.br';
            // host = 'sistemab.consultoriaasap.com.br'
            // host = 'sistemabargentina.portal.hmgasap.com.br';
            // host = 'sistemabchile.portal.hmgasap.com.br';
            // host = 'redemmaranhao.consultoriaasap.com.br';
            // host = 'avance.consultoriaasap.com.br';
            // host = 'institutococacola.portal.hmgasap.com.br';

        }
        localStorage.setItem('clientURL', host);

        this.client.getColorByHost(host).subscribe((response: any) => {
            console.log('[asap-login] Color Theme:', response);
            if (response.data) {
                localStorage.setItem('config_color', JSON.stringify(response.data));
            
                const dictionary = response.data.config;
                
                if (dictionary) {
                    this.auth.syncActiveClient(response.data.client_id);
                    AsapClientPipe.setDictionary(dictionary);
    
                    const html = document.querySelector('html');
    
                    html.style.setProperty('--bg-client', AsapClientPipe.dictionary.bgClient);
    
                    html.style.setProperty('--main-color', AsapClientPipe.dictionary.mainColor);
    
                    html.style.setProperty('--bg-navbar', AsapClientPipe.dictionary.bgNavbar);
                    html.style.setProperty('--link-navbar', AsapClientPipe.dictionary.linkNavbar);
    
                    html.style.setProperty('--bg-sidebar', AsapClientPipe.dictionary.bgSidebar);
                    html.style.setProperty('--link-sidebar', AsapClientPipe.dictionary.linkSidebar);
    
                    html.style.setProperty('--bg-footer', AsapClientPipe.dictionary.bgFooter);
                    html.style.setProperty('--link-footer', AsapClientPipe.dictionary.linkFooter);
    
                    html.style.setProperty('--bg-btn', AsapClientPipe.dictionary.bgBtn);
                    html.style.setProperty('--text-btn', AsapClientPipe.dictionary.textBtn);
    
                    // Não mexa nas variáveis do visualizador sem a permissão do Sérgio
                    // Quem mexer é corno!
                    html.style.setProperty('--bg-sidebar-viewer', AsapClientPipe.dictionary.bgSidebarViewer);
                    html.style.setProperty('--bg-btn-sidebar-viewer-active', AsapClientPipe.dictionary.bgBtnSidebarViewerActive);
                    html.style.setProperty('--bg-btn-sidebar-viewer-text-active', AsapClientPipe.dictionary.bgBtnSidebarViewerTextActive);
                    html.style.setProperty('--icon-sidebar-viewer', AsapClientPipe.dictionary.iconSidebarViewer);
                    html.style.setProperty('--link-sidebar-viewer', AsapClientPipe.dictionary.linkSidebarViewer);
    
                    html.style.setProperty('--bg-btn-viewer', AsapClientPipe.dictionary.bgBtnSidebarViewerActive);
                    html.style.setProperty('--text-btn-viewer', AsapClientPipe.dictionary.bgBtnSidebarViewerActive);
                    html.style.setProperty('--text-btn-viewer-active', AsapClientPipe.dictionary.bgBtnSidebarViewerTextActive);
                    html.style.setProperty('--icon-sidebar-viewer', AsapClientPipe.dictionary.iconSidebarViewer);
    
                    html.style.setProperty('--primary-color', AsapClientPipe.dictionary.primaryColor);
                    html.style.setProperty('--primary-color-hover', AsapClientPipe.dictionary.primaryColorHover);
    
                    html.style.setProperty('--app-logo', AsapClientPipe.dictionary.logoDark);
    
                    // Guardando váriavel de configuração de Linguagem
                    localStorage.setItem('language', AsapClientPipe.dictionary.language);
                    // tslint:disable-next-line: max-line-length
                    localStorage.setItem('imageURL', `https://ui-avatars.com/api/?background=${AsapClientPipe.dictionary.bgBtn}&color=${AsapClientPipe.dictionary.textBtn}&size=400&bold=true&name=`)
    
                    setTimeout(() => {
                        this.title.setTitle(AsapClientPipe.dictionary.appName);
                        // document.getElementById('favicon').setAttribute('href', AsapClientPipe.dictionary.favicon);
                        this.theme.theme = response.data;
                        this.theme.themeLoaded = true;
                        if (AsapClientPipe.dictionary.loginAD) {
                            router.navigate(['/active-directory']);
                        }
                        else {
                            router.navigate(['/login']);
                        }                        
                        configLoaded = true;
                    }, 100);
                } 
                else {
                    setTimeout(() => {
                        this.title.setTitle('Cliente não Encontrado!');
                        this.theme.themeLoaded = true;
                        this.theme.clientNotFound = true;
                        this.router.navigate(['/not-found']);
                        configLoaded = true;
                    }, 100);
                }
            }
            else {
                setTimeout(() => {
                    this.title.setTitle('Cliente não Encontrado!');
                    this.theme.themeLoaded = true;
                    this.theme.clientNotFound = true;
                    this.router.navigate(['/not-found']);
                    configLoaded = true;
                }, 100);
            }
        });

    }

}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AsapClientPipe } from 'src/app/pipes/asap-client.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-redirect',
  template: `Carregando...`
})
export class RedirectComponent {

  public token: string;
  public url: string;
  public subs: string;

  constructor(
    private _theme: ThemeService,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router
  ) {

    this._activatedRoute.queryParams.subscribe(params => {
      if (params['token']) {
        console.log('RedirectComponent: queryParams', params);
        this.token = params['token'];
        this.url = params['url'];
        this.subs = params['subs'];
        this.tokenLogin();
      }
      else {
        console.log("[ClientNotFound]",this._theme.clientNotFound);
        if (this._theme.clientNotFound) {
          _router.navigate(['/not-found']);
        }
        else {
          if (AsapClientPipe.dictionary.loginAD) {
            _router.navigate(['/active-directory']);
          }
          else {
            _router.navigate(['/login']);
          }
        }
      }
    });
  }

  tokenLogin() {
    const token = 'Bearer' + ' ' + this.token;
    localStorage.setItem('token', token);
    sessionStorage.setItem('token', token);

    localStorage.setItem('plainToken', this.token);
    sessionStorage.setItem('plainToken', this.token);

    this._authService.getUserLogged().subscribe((res:any) => {
      this._authService.verifica().subscribe((verifica:any) => {
        localStorage.setItem('smart_verifica', (verifica.success) ? '1' : '0');
        localStorage.setItem('loggedUser', JSON.stringify(res));
        this._router.navigate([this.url], { queryParams: { subs: this.subs } });
      });
    });
  }

}

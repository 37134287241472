import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            isolate: false
        })
    ],
    exports: [
      TranslateModule
    ],
    providers: [ TranslateService ]
})
export class TranslateSharedModule {

    constructor(translate: TranslateService) {
        const getLanguage = localStorage.getItem('language');
        let language = 'pt-br';
        if (getLanguage && getLanguage != 'undefined' && getLanguage != '') {
            language = getLanguage;
        }
        console.log('LANGUAGE SET ->', language);
        translate.setDefaultLang(language);
        translate.use(language);
        console.log('TRANSLATE ->', translate);
    }

 }

import { Injectable } from '@angular/core';
import { Store } from './store';

export class VoucherState {
  voucher: {
    client_id: number,
    field: string
    voucher_id: number,
    used: number
    users: number
    value: string
  } = null;
  isValid = false;
}

@Injectable({providedIn: 'root'})
export class VoucherStore extends Store<VoucherState> {
  constructor() {
    super(new VoucherState());
  }

  setVoucher(voucher: any): void {
    this.setState({
      ... this.state,
      voucher,
      isValid: true
    });
  }

  reset() {
    this.setState(new VoucherState());
  }

}

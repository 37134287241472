import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ThemeService } from './theme.service';
import { BaseService } from 'asap-crud'
import { Observable } from 'rxjs';
import { AsapClientPipe } from '../pipes/asap-client.pipe';
import { ClienteService } from './client.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService extends BaseService {

    constructor(
        public client: ClienteService,
        public theme: ThemeService,
        public http: HttpClient,
        public router: Router
    ) {
        super(http);
    }

    uniqueCodeAuth(code: string): Observable<any> {
        const client_id = localStorage.getItem('client');
        return this.http.post(this.baseUrl + '/api/unique/auth?client_id=' + client_id, { code: code });
    }

    login(user:any, loginAD = false): any {
        let body;
        let endPoint;
        if (loginAD) {
            endPoint = "active-directory";
            body = {
                username: user.username,
                password: user.password
            };
        }
        else {
            endPoint = "oauth/access_token";
            body = {
                email: user.username,
                password: user.password
            };
        }

        let client_id = localStorage.getItem('client')
        return this.http.post(this.baseUrl + '/api/' + endPoint + '?client_id=' + client_id, body);
    }

    loginToken(token:string, brand:any = null) {
        let client_id = localStorage.getItem('client');

        let body: any = {
            token: token
        };

        if (brand == "bio") {
            body.brand = brand;
        }

        return this.http.post(this.baseUrl + '/api/oauth/access_token?client_id=' + client_id, body);
    }

    register(user: any, clientId: number): Observable<any> {
        const body = {
            client_id: clientId,
            user
        };
        const url = this.baseUrl + '/api/v2/client/register';
        return this.http.post(url, body);
    }

    isAuthenticated(): boolean {
        const token: string = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        return true;
    }

    public getLinkStream() {
        let client_id = localStorage.getItem('client')
        return this.http.get(this.baseUrl + '/api/v2/admin/user/stream?client_id=' + client_id, this.options());
    }

    setSessionToken(data:any): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const token = 'Bearer' + ' ' + data.token;

            localStorage.setItem('token', token);
            localStorage.setItem('plainToken', data.token);
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('plainToken', data.token);

            if (data.company) {
                localStorage.setItem('company', JSON.stringify(data.company));
            }

            if (data.tokenLogin) {
                localStorage.setItem('tokenLogin', data.tokenLogin);
                sessionStorage.setItem('tokenLogin', data.tokenLogin);
            }

            this.getUserLogged().subscribe(res => {
                this.verifica().subscribe(verifica => {
                    localStorage.setItem('smart_verifica', (verifica.success) ? '1' : '0');
                    localStorage.setItem('supplementary_data_check', (verifica.success) ? '1' : '0');
                    localStorage.setItem('login_voucher_enable', (res.data.login_voucher_enable) ? '1' : '0');
                    if (res.success === false) {
                        resolve(false);
                    } else {
                        this.getLinkStream().subscribe(
                            (response: any) => {
                                res.data.link = res.data.link;
                                res.data.channeStream = (res.data.link_company) ? res.data.link_company : res.data.link;
                                this.syncActiveClient(res.data.client_id, true).then(() => {
                                    localStorage.setItem('loggedUser', JSON.stringify(res));
                                    localStorage.setItem('apiURL', environment.apiUrl);
                                    this.verifyMentoria(res.data.id);
                                    resolve(true);                                   
                                });
                            },
                            (error: any) => {
                                console.log('error', error);
                            }
                        )
                    }
                });
            }, err => reject(err));
        });
    }

    setSessionTokenLogin(data:any): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const token = 'Bearer' + ' ' + data.access_token;
            localStorage.setItem('token', token);
            localStorage.setItem('plainToken', data.acess_token);

            sessionStorage.setItem('token', token);
            sessionStorage.setItem('plainToken', data.acess_token);
            this.getUserLogged().subscribe(res => {
                this.verifica().subscribe(verifica => {
                    localStorage.setItem('smart_verifica', (verifica.success) ? '1' : '0');
                    localStorage.setItem('supplementary_data_check', (verifica.success) ? '1' : '0');
                    localStorage.setItem('login_voucher_enable', (res.data.login_voucher_enable) ? '1' : '0');
                    if (res.success === false) {
                        resolve(false);
                    } else {
                        localStorage.setItem('loggedUser', JSON.stringify(res));
                        resolve(true);
                    }
                });
            }, err => reject(err));
        });
    }

    tokenLogin(token:string, brand:any = null) {
        return new Promise((resolve, error) => {
            //this.blockUI.start("Token encontrado, efetuando Logon...")
            this.loginToken(token, brand).subscribe(
                (data) => {
                    console.log("tokenLogin.data:", data);
                    this.setSessionToken(data)
                        .then((response) => {
                            localStorage.setItem("tokenLogin", token);
                            resolve(response);
                        })
                        .catch((err) => {
                            error(err);
                        });
                },
                (err) => {
                    console.log("tokenLogin.error:", err);
                    error(err);

                }
            );
        });
    }

    socialAuth(body:any) {
        const client_id = localStorage.getItem('client');
        return this.http.post(environment.baseUrl + '/api/social_auth?client_id=' + client_id, body);
    }

    getUserLogged(): Observable<any> {
        return this.http.get(this.baseUrl + '/api/v2/client/user/authenticated', this.options());
    }

    verifica(): Observable<any> {
        return this.http.get(environment.baseUrl + '/api/v2/client/verifica', this.options());
    }

    complement(): Observable<any> {
        return this.http.get(environment.baseUrl + '/api/v2/admin/user/form', this.options());
    }

    complementSave(id: number, data: any): Observable<any> {
        return this.http.put(environment.baseUrl + '/api/v2/perfil/sobre/' + id, data, this.options());
    }

    verifyClient(clientId: number): Observable<any> {
        return this.http.post(environment.baseUrl + '/api/v2/client/verifyclient', { client_id: clientId }, this.options());
    }

    dismissStorage(): void {
        localStorage.clear();
        sessionStorage.clear();
    }

    syncActiveClient(client: string, getColor:boolean = false) {
        localStorage.setItem('client', client);
        if (getColor) {
            return new Promise((resolve) => {
                this.client.getColorByID(client).subscribe((response) => {
                    localStorage.setItem('config_color', JSON.stringify(response.data));
                    this.theme.theme = response.data;
                    resolve(true);
                });

            })
        }
        return null;
    }


    getActiveClient(): any {
        return +localStorage.getItem('client');
    }

    userInfo(id:string): Observable<any> {
        return this.http.get(environment.baseUrl + '/api/v2/client/feed/userInfo/' + id, this.options());
    }

    verifyMentoria(id:string) {
        const url = this.baseUrl + '/api/v2/client/user-mentor/check';
        this.http.get(url, this.options()).subscribe((res: any) => {
            localStorage.setItem('isMentor', (res.status) ? '1' : '0');
        });
    }

    redirect(): void {
        const refUrl = localStorage.getItem('ref');

        const sdRequired = !((!AsapClientPipe.dictionary.supplementaryDataRequired) || (AsapClientPipe.dictionary.supplementaryDataRequired == 'false'));
        const check = localStorage.getItem('supplementary_data_check');
        const lu = localStorage.getItem('loggedUser');

        if (lu && sdRequired && check === '0') {
            console.log("[asap-login] AuthService HREF A");
            window.location.href = "/on/dados-complementares"
        }
        else {
            if (refUrl) {
                localStorage.removeItem('ref');
                console.log("[asap-login] AuthService HREF B");
                window.location.href = refUrl;
            } 
            else {
                this.theme.usePortal();
                localStorage.setItem('platform', 'smart');
    
                if (this.theme.usePortal()) {
                    console.log("[asap-login] AuthService HREF C");
                    window.location.href = "/on/portal";
                } else {
                    console.log("[asap-login] AuthService HREF D");
                    window.location.href = "/on/universidade";
                }
            }
        }
    }

}
